<template>
  <Layout :is-loading="pending || Boolean(error)">
    <template #default>
      <Checkout v-if="data" :policy-id :total-price="data?.payment.total" />
    </template>

    <template #aside>
      <div class="space-y-24">
        <h2 class="heading-3">{{ i18n(translations.summaryTitle) }}</h2>

        <DueSummary v-if="data" :payment="data.payment" :policy="data.policy" />
      </div>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { createError, navigateTo, useRouteParams } from '#imports'

import { HttpApiError } from '@backmarket/http-api'
import { getInsuranceDuePayment } from '@backmarket/http-api/src/api-specs-insurance/insurances/insurances'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import Layout from '~/scopes/insurance/components/Layout/Layout.vue'

import { INSURANCE } from '../../routes.constants'

import translations from './DueInsurance.translations'
import Checkout from './components/Checkout/Checkout.vue'
import DueSummary from './components/DueSummary/DueSummary.vue'

const i18n = useI18n()
const { policyId } = useRouteParams()

const { data, error, pending } = await useHttpFetch(getInsuranceDuePayment, {
  pathParams: { policyId },
})

if (error.value) {
  const httpError = HttpApiError.fromAnyError(error.value)
  if (
    httpError.status === 400 &&
    httpError.type === '/errors/insurance/no-missed-occurrences'
  ) {
    void navigateTo({ name: INSURANCE.DUE_PAYMENT_COMPLETED })
  } else {
    throw createError({
      statusCode: httpError.status,
      message: httpError.message,
    })
  }
}
</script>

<template>
  <SummaryCard
    :subtotal="payment.subtotal"
    :tax="payment.tax"
    :total="payment.total"
  >
    <template #default>
      <div class="flex gap-12">
        <div>
          <div class="border-static-default-mid rounded-sm shrink-0 border p-8">
            <IconTools size="24" />
          </div>
        </div>

        <div class="w-full space-y-8">
          <h3 class="body-2-bold max-w-[12rem]">{{ policy.name }}</h3>

          <div class="space-y-16">
            <ul class="space-y-8" data-test="occurrences-list">
              <li
                v-for="occurrence in payment.missingOccurrences"
                :key="occurrence.dueDate"
                class="flex justify-between gap-8"
              >
                <span class="grow">
                  {{
                    i18n(translations.occurrence, {
                      date: i18n.date(new Date(occurrence.dueDate)),
                    })
                  }}
                </span>
                <span>{{ i18n.price(occurrence.price) }}</span>
              </li>
            </ul>

            <p class="body-2-bold">
              {{ i18n(translations.product, { model: policy.product.name }) }}
            </p>
          </div>

          <p>
            {{
              i18n(translations.purchaseDate, {
                date: i18n.date(new Date(policy.subscriptionDate)),
              })
            }}
          </p>
        </div>
      </div>
    </template>

    <template #footer>
      <p class="text-static-default-low caption">
        {{ i18n(translations.recurringPaymentDetails) }}
      </p>
    </template>
  </SummaryCard>
</template>

<script setup lang="ts">
import type {
  DuePayment,
  DuePolicy,
} from '@backmarket/http-api/src/api-specs-insurance/insurances/insurances.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconTools } from '@ds/icons/IconTools'

import SummaryCard from '~/scopes/insurance/components/SummaryCard/SummaryCard.vue'

import translations from './DueSummary.translations'

const i18n = useI18n()

defineProps<{
  payment: DuePayment
  policy: DuePolicy
}>()
</script>

import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types'

const translations: Record<string, I18nDefinition> = {
  summaryTitle: {
    id: 'insurance_missed_occurrence_summary_title',
    defaultMessage: 'What’s due',
  },
}

export default translations
